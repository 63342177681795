
.calender-icon {
    margin-right: 10px;
}
.location-icon {
    margin-right: 10px;
}
.ticket-icon {
    margin-right: 10px;
}
.filter-icon {
    margin-right: 10px;
}
.task-icon {
    margin-right: 10px;
}
.logo {
    display: flex;
    .logo-soniq {
      justify-content: center;
      align-items: center;
    }
    .logo-label {
      display: flex;
      align-items: flex-end;
      margin-left: 10px;
      line-height: 16px;
      .name {
        width: 56px;
      }
      .sign {
        font-size: 4px;
        height: 13px;
      }
    }
}