@import '@/styles/variables.less';

.main-calendar{
    .calendar-picker-container{
        .ant-picker{
            border: none;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 10;
            top: 0;
            left: 0;  
            opacity: 0;
            cursor: pointer;
        }
       
    }
}

.ant-picker-dropdown {
    .ant-picker-panel-container{
        .ant-picker-year-panel {
            .ant-picker-header {
                .ant-picker-decade-btn {
                    pointer-events: none; 
                }
            }
        }
    }
}