@import '@/styles/variables.less';

.illustration {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    .content-title {
        text-align: center;
        width: 450px;
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
        margin: 100px auto 20px auto;
    }
    .content-img {
        width: 100%;
        margin-bottom: 40px;
    }
    .wapper-slider {
        position: relative;
        .slick-list {
            border-radius: 10px;
        }
        .content-icon {
            position: absolute;
            z-index: 1;
            top: -10px;
        }
 
        .slick-dots {
            margin-bottom: -20px;
            li button:before {
                color: @primaryColor; 
            }
            li.slick-active button:before {
                color: @primaryColor; 
            }
        }
    }
    .content-slider {
        margin: 0 auto;
        height: 142px;
        width: 70%;
        background-color: @whiteColor;
        border-radius: 10px;
        box-shadow: 0px 4px 8px rgba(2, 116, 232, 0.15);
        text-align: center;
        .text {
            font-size: 12px;
            line-height: 16px;
            padding-top: 40px;
            width: 70%;
            margin: 0 auto;

        }
        .sign {
            margin-top: 10px;
            font-size: 14px;
            font-weight: 500;
        }

    }
}