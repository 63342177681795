@import '@/styles/variables.less';
    .ant-drawer-header{
        border: none !important;
    }
    
    .ant-drawer-footer{
        border: none;
    }
    .ant-drawer-content-wrapper{
        .ant-drawer-body{
            padding: 0 28px 0 22px;
        }
    }

    
    .header-drawer-container{
        color: @fourthColor;
        // border-bottom: 1px solid @thirdColor;
        .title {
            font-weight: bold;
            font-size: 18px;
            margin: 24px 0 16px 0;
        }
        .time{
            font-size: 12px;
            font-weight: bold;
            line-height: 8px;
        }
        .location{
            font-size: 14px;
            margin-top: 16px;
            text-transform: capitalize;
            line-height: 20px;
            color: @fourthColor
        }
        .location-detail{
            font-size: 14px;
            margin-top: 16px;
            text-transform: capitalize;
            color: @fourthColor
        }
        .address {
            font-size: 14px;
            color: @fourthColor
        }
        .process-label{
            max-width: 100px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            border-radius: 4px;
            text-transform: uppercase;
            &.pause{
                background: @plannedColor;
                color: @planedTextColor;
            }
            &.inprogress{
                background: @inprogressColor;
                color: @inprogressTextColor;
            }
            &.complete {
                background: @completeColor;
                color: @completeTextColor;
            }
            &.inactive {
                background:  @notCompleteColor;
                color: @notCompleteTextColor;
            }
    
        }
       
    }
 
    
    .content-drawer-container{
        color: @fourthColor;
        padding: 0 0 16px 0;
      
        .ant-collapse-content-box{
            padding: 16px 0 16px 0;
        }
        .ant-collapse-item > .ant-collapse-header{
            padding: 12px 0px !important;
        }
       
    
        .task-container{
            margin: 24px 0 24px 0;
            .area {
                font-weight: bold;
                font-size: 16px;
                
            }
            .task-detail{
                font-size: 14px;
                color: @fifthColor;
                height: 0;
                overflow: hidden;
                &.expand{
                    margin: 8px 0  8px 0;
                    height: auto;
                   
                }
            }
            .expand-btn {
                font-size: 14px;
                color: @primaryColor;
                cursor: pointer;
                opacity: 0.8;
                &:hover{
                    opacity: 1;
                }
            }
    
        }
        .open-ticket-item{
            font-size: 14px;
            color: @primaryColor;
            margin-bottom: 8px;
        }
        .open-ticket-item-container{
            margin-bottom: 16px;
            display: flex;
            flex-direction: column;
           
    
            .open-ticket-dropdown-btn{
                width: 100%;
                height: 36px;
                border-radius: 4px;
                border: 1px solid @eighthColor;
                align-items: center;
                display: flex;
                color: @ninethColor;
                font-size: 14px;
                justify-content: space-between;
                padding: 8px 16px;
            }
    
            .open-ticket-label{
                font-size: 12px;
                color: @fourthColor;
                margin-bottom: 4px;
            }

            .open-ticket-comment{
                padding: 8px 16px;
                border-radius: 4px;
                color: @ninethColor;
                border: 1px solid @eighthColor;
            }
            .open-ticket-save-btn{
                align-self: flex-end;
                border-radius: 4px;
                background-color: @primaryColor;
            }
        }
     
        
      
    }
    
    .ant-collapse{
        background: transparent !important;
        border: none !important;
        font-size: 14px;
        padding: 0;
        color: @fourthColor;
        .ant-collapse-item{
            border: none;
            border-top: 1px solid @thirdColor !important;
            border-bottom: 1px solid @thirdColor !important;
            .ant-collapse-header{
                padding-left: 0;
            }
        
           
        }
        .menu-item{
            display: flex;
            align-items: center;
            .menu-type-label{
                font-size: 14px;
                margin-left: 10px;
            }
            .menu-icon{
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 1px solid @fourthColor;
            }
        }
    }

  
.task-item-container{
   
    display: flex;
    margin-bottom: 16px;
    line-height: 24px;
    .task-item-label {
        flex: 0 0 85%;
        font-size: 14px;
        color: @fourthColor;
        text-transform: capitalize;
        
    }
    .task-item-status{
        flex: 0 0 15%;
        .round {
            position: relative;
        }
        .round label {
            background-color: @whiteColor;
            border: 2px solid @eleventhColor;
            border-radius: 50%;
            cursor: pointer;
            height: 24px;
            left: 0;
            position: absolute;
            top: 0;
            width: 24px;
        }
        .round label.checked {
            background-color: @eleventhColor;
            border-color: @eleventhColor;
            svg{
                position: absolute;
                top: 5px;
                left: 5px;
                width: 10px;
                height: 10px;
                color: white;
            }
        }

    
    }
}    
