@import '@/styles/variables.less';

.module-wrapper_private{
    padding: 8px 40px 8px 40px;
    overflow: hidden;
    height: 94%;
    background: @whiteColor;
}

.module-wrapper_public {
    overflow: hidden;
    height: 100%;
    background: @whiteColor;
}