@import '@/styles/variables.less';

.auth-container {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  .illustration-container {
    width: 40%;
    background-color: #F7FBFF;
  }
  .registration-container {
    width: 60%;
    padding: 80px;
    .container-header {
      display: flex;
      justify-content: space-between;
    }
    .login-header {
      margin-top: 30px;
      margin-bottom: 30px;
      .title {
        font-size: 30px;
        font-weight: bold;
      }
    }
    .content {
        color: @fourthColor;
        .input-temporary {
            margin-bottom: 50px;
        }
        .list-require {
          padding-inline-start: 15px;
        }
      }
    .login-btn {
      margin-top: 20px;
    }
    .login-registration-error {
      color: @errorTextColor;
    }
    .ant-form-item-control-input-content {
      .ant-btn-primary {
        border-radius: 4px;
        min-width: 140px;
        height: 40px;
        background: @loginBtnColor;
        border-color: @loginBtnColor;
        &:hover,
        &:focus {
          background: @loginBtnColor;
          border-color: @loginBtnColor;
        }
      }
        .ant-input-password {
          border-radius: 4px;
        }
      }
  
    .ant-form-vertical .ant-form-item-label {
      padding: 0;
      > label {
        font-size: 13px;
        ::after {
          content: " ";
        }
      }
    }
    .ant-input {
      border-radius: 4px;
    }
  }
}

  