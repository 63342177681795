@import '@/styles/variables.less';

    .calendar-container{
        transition: all 0.4s linear;
        &.blur{
            filter: blur(1px);
            transition: all 0.4s linear;
        }
        .fc .fc-timegrid-slot-minor{
            border-style: solid;
        }
        .fc .fc-timegrid-col.fc-day-today{
            background: white;
        }
        .fc .fc-timegrid-slot{
            height: 2rem;
        }
       
        .fc-theme-standard .fc-scrollgrid{
            border: none !important;
        }
        .fc .fc-timegrid-axis-frame{
            display: none !important;
        }
        
        .fc-scrollgrid-sync-inner{
            padding-top: 1rem;
            padding-bottom: 1rem;
            border: 0px;
        
            .fc-col-header-cell-cushion {
                font-size: 24px;
                word-wrap: break-word;
                .calender-week-days {
                    font-size: 12px;
                    font-weight: 100;
                    text-transform: uppercase ; 
                }
                .calender-month-days {
                    font-size: 24px;
                    font-weight: 500;
                }
            }
        }
    
        .fc-day-past,.fc-day-future{
            .fc-col-header-cell-cushion {
                color: @fifthColor;
            }
            
        }
        .fc-scrollgrid-sync-inner {
            border-bottom: 1px solid @thirdColor !important;
            flex-direction: column;
            background: @whiteColor;
        }
    
        .fc-daygrid-day{
            font-size: 16px;
            &.fc-day-past, &.fc-day-future{
                .fc-daygrid-day-number{
                    color: @fifthColor;
                }
            }
        }
    
        .fc .fc-daygrid-body-natural .fc-daygrid-day-events{
            margin: 0 !important;
        }
        .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events{
            min-height: 0 !important;
        }

        
        .fc-timegrid-event-harness{
            &:hover{
                z-index: 1000 !important;
                width: 100%;
                // height: 150px;
            }
            
            .fc-v-event{
                background: @secondColor;
                border-radius: 4px;
                border-top: 3px solid @primaryColor !important;
                border: none;
                padding: 8px;
               
                .fc-event-time{
                    color: @primaryColor;
                    font-size: 12px;
                }
                .fc-event-title{
                    color: @primaryColor;
                    font-size: 12px;
                }
               
            }
        }
       
    
        .fc-daygrid-event-harness{
            .fc-h-event{
                border: none;
                margin-bottom: 16px;
            }
            .fc-event-main{
                background: @secondColor;
                border-bottom: none;
                border-left: none;
                border-right: none;
                border-top: 3px solid @primaryColor !important;
                padding: 1px 8px 8px 8px;
                color: @primaryColor;
                font-size: 12px;
            }
             .fc-daygrid-dot-event:hover {
                 background: @inprogressColor;
             }
        }
        .fc .fc-daygrid-body-balanced .fc-daygrid-day-events{
            position: relative;
        }
    
        
        .fc-popover {
            padding: 16px 8px 16px 8px;
            box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
            border-radius: 4px;
            .fc-popover-header{
                background: @whiteColor;
                padding: 14px 16px 16px 14px;
                font-size: 15px;
                color: @fourthColor;
                border-bottom: 1px solid @thirdColor;
            } 
            .fc-daygrid-event-dot{
                margin-bottom: 8px;
            }
            .fc-event-time{
                font-size: 12px;
                color: @fourthColor;
                margin-right: 8px;
                margin-bottom: 8px;
            }
            .fc-event-title{
                font-size: 12px;
                color: @fourthColor;
                margin-bottom: 8px;
            }
        }
    
    
        .fc .fc-timegrid-now-indicator-line {
            background: @primaryColor;
            border: 1px solid @primaryColor;
            &::before{
                content: '';
                position: absolute;
                top: -4px;
                left: -1px;
                width: 8px;
                height: 8px;
                z-index: 541;
                border-radius: 50%;
                background-color: @primaryColor;
            }
        }
    
    
        .fc .fc-timegrid-now-indicator-arrow{
            display: none;
        }
    
        .fc-col-header-cell-cushion {
            color: @primaryColor;
        }
    
        .fc-daygrid-event{
            .fc-event-time{
                color: @primaryColor;
            }
    
            .fc-event-title{
                color: @primaryColor;
                &.fc-sticky {
                    color: @primaryColor
                }
            }
        }
    
        .fc-daygrid-day{
            .fc-daygrid-day-number{
                color: @primaryColor
            }
        }
    
        .fc-daygrid-day-bottom{
            .fc-daygrid-more-link{
                color: @primaryColor;
            }
        }

        .fc .fc-more-popover .fc-popover-body{
            height: 40vh;
            // overflow-y: scroll;
        }


        .fc-cell-shaded{
            display: none !important;
        }
        .fc-theme-standard td, .fc-theme-standard th {
           border: 0px  !important;
           background: transparent;
           border-right: 1px solid @thirdColor !important;
        }
        .fc-scrollgrid-sync-inner{
            border-top: 1px solid @thirdColor !important;
        }
        .fc-timeGridWeek-view, .fc-timeGridDay-view{
            .fc-timegrid-slot{
                &.fc-timegrid-slot-lane{
                    border-top: 1px solid @thirdColor  !important;
                }
                &.fc-timegrid-slot-minor{
                    border-top: 0px  !important;
                }
            } 
        }
       
       

        .fc-scrollgrid-shrink-cushion{
            font-size: 12px;
            color: @fifthColor;
            position: absolute;
            margin-top: -20px;
            margin-left: -49px;
        }
        .fc-col-header-cell.fc-day{
            border-right: 0px !important;
        }
        // .fc-timeGridWeek-view,.fc-timeGridDay-view{
            .fc .fc-scroller-harness-liquid {
                height: 61vh;
            }
        // }
        .fc-dayGridMonth-view{
            .fc-scroller-harness-liquid {
                height: 74vh !important;
            } 
        }
    
        .fc-col-header-cell,.fc-timeGridWeek-view,.fc-timeGridDay-view{
            .fc-scrollgrid-sync-inner{
                height: unset !important;
            }
        }
        .fc-daygrid-day.fc-day{
            .fc-scrollgrid-sync-inner{
                height: 20vh;
            }
        }

        .fc-dayGridMonth-view,.fc-timeGridDay-view{
            .fc-col-header-cell-cushion{
                color: @fifthColor;
                font-size: 12px;
                font-weight: 400;
                text-transform: uppercase;
            }
        }
        
    }



   