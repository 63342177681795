@import '@/styles/variables.less';

.auth-container {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  .illustration-container {
    width: 40%;
    background-color: #F7FBFF;
  }
  .login-container {
    width: 60%;
    padding: 80px;
    .container-header {
      display: flex;
      justify-content: space-between;
    }
    .login-form-container {
      height: 100%;
      position: relative;
    }
    .login-header {
      margin-top: 30px;
      margin-bottom: 30px;
      .title {
        font-size: 30px;
        font-weight: bold;
      }
      .content {
        color: @fourthColor;
        text-align: left;
        width: 425px;
      }
      .list-require {
        padding-inline-start: 15px;
      }
    }
    .ant-col-9 {
      .login-password {
        margin-bottom: 0px;
      }
    }
    .login-btn {
      width: 140px;
      margin-top: 20px;
    }
    .recovery-btn {
      width: 250px;
      margin-top: 20px;
      border-radius: 4px;
      background: @loginBtnColor;
      border-color: @loginBtnColor;
      &:hover,
      &:focus {
        background: @loginBtnColor;
        border-color: @loginBtnColor;
      }
    }
    .login-footer {
      bottom: 50px;
      position: absolute;
    }
    .login-registration-error {
      color: @errorTextColor;
    }
    .ant-form-item-control-input-content {
      .ant-btn-primary {
        border-radius: 4px;
        height: 40px;
        background: @loginBtnColor;
        border-color: @loginBtnColor;
        &:hover,
        &:focus {
          background: @loginBtnColor;
          border-color: @loginBtnColor;
        }
      }
      .ant-input-password {
        border-radius: 4px;
      }
    }
    .forgot-password-container {
      min-height: 500px;
    }
    .forgot-password {
      z-index: 99;
      position: relative;
      .forgot-password-btn {
        padding: 0;
        top: 5px;
        right: 0px;
        position: absolute;
        font-size: 12px;
        color: @planedTextColor;
        &:hover {
          background: rgba(0, 0, 0, 0);
        }
      }
    }
    .remember-password-btn {
      font-size: 14px;
      &:hover {
        background: rgba(0, 0, 0, 0);
      }
    }
    .ant-form-vertical .ant-form-item-label {
      padding: 0;
      > label {
        font-size: 13px;
        ::after {
          content: " ";
        }
      }
    }
    .ant-input {
      border-radius: 4px;
    }
  }
}
