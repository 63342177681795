@import '@/styles/variables.less';

@avatarListColor : @firstAvatarColor , @secondAvatarColor , @thirdAvatarColor ,@fourthAvatarColor,@fifthAvatarColor,@sixthAvatarColor,@seventhAvatarColor,@eighthAvatarColor,@ninethAvatarColor,@tenthAvatarColor;
@length: length(@avatarListColor);
@random: `Math.ceil(Math.random() * (@{length}))`;
@randomcolor: extract(@avatarListColor,@random);

.avatar-container{
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;  

    &.photo{
        position: relative;
        overflow: hidden;
        img{
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
    &.placeholder{
        background: @randomcolor; 
        color: @whiteColor;
        font-weight: bold;
    }
    &.sm{
        width: 32px;
        height: 32px;
        font-size: 12px;
    }
    &.md{
        width: 48px;
        height: 48px;
        font-size: 16px;
    }
    &.lg{
        width: 56px;
        height: 56px;
        font-size: 18px;
    }
    &.x-lg{
        width: 64px;
        height: 64px;
        font-size: 19px;
    }
    
}
