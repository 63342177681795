@import '@/styles/variables.less';

.login-header {
  margin-top: 30px;
  margin-bottom: 30px;
  .title {
    font-size: 30px;
    font-weight: bold;
  }
  .content {
    color: @fourthColor;
  }
  .list-require {
    padding-inline-start: 15px;
  }
}