@import '@/styles/variables.less';
@import '~antd/dist/antd.less';

.ant-layout-content{
    background: white;
    border: 1px rgba(0, 0, 0, 0.08) solid;
}
.ant-layout-header {
    padding: 0;
}
::-webkit-scrollbar {
    width: 0px;
}
  