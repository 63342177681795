@import '@/styles/variables.less';
.contact-info-container{
    margin-top: 16px;  
    border-bottom: 1px solid @thirdColor;
    padding-bottom: 56px;
    .avatar-update-container{
        display: flex;
        align-items: center;
        .avatar-update-button{
            margin-left: 24px;
            height: 40px;
            border-radius: 4px ;
            border: 1px solid @eighthColor;
            font-size: 14px;
            color: @fourthColor;
        }
    }
    .contact-info-title{
        margin-top: 16px;  
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        color: @seventhColor
    }
    .contact-info-sub-title{
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: @tenthColor
    }
    .contact-info-form-container{
        margin-top: 24px;
        .contact-info-form{
            .label{
                font-size: 12px;
                font-weight: bold;
            }  
            .input{
                margin-top: 6px;
                height: 40px;
                border: 1px solid @eighthColor;
                padding: 8px 12px;
                border-radius: 4px;
                width: 95%;
              
            }
        }
    }
}