@import '@/styles/variables.less';

.ant-layout-header{
    padding: 0;
    height: 80px;
}

.header-container {
    width: 100%;
    height: 100%;
    padding-right: 24px ;
    background: @whiteColor;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
    // .anticon {
    //     font-size: 16px;
    // }
    .ant-menu-item-selected{
        border-bottom: 0;
    }
    .ant-menu-horizontal {
        border: none;
    }
    .info-menu {
        border: 1px solid @seventhColor;
    }   
    .ant-menu-item.ant-menu-item-only-child{
        border: 0   !important;
        :hover {
            color: @seventhColor;
        }
    }
    .ant-menu-item.ant-menu-item-only-child.ant-menu-item-active{
        border-bottom: 0 !important;
        :hover {
            color: @lightBlue;
        }
    }
    .ant-btn{
        border-color: transparent ;
    }
    .ant-btn:focus{
        color: rgba(0, 0, 0, 0.85);
        border-color: @lightWhite;
    }
    .ant-btn:hover {
        color: @lightBlue;
    }
    .arrow {
        margin-left: -5px;
    }
    .dropdown-btn {
        width: 36px; 
        height: 40px;
    }
    .ant-menu-item.ant-menu-item-only-child.ant-menu-item-active {
        color: @whiteColor;
    }
    .ant-menu-item.ant-menu-item-only-child.ant-menu-item-active :hover {
        color: @whiteColor;
    }
}
.info-menu {
    width: 295px;
    background-color: @whiteColor;
    height: auto;
    box-shadow: 0px 0.5px 2px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.13);
    border-radius: 4px;
    .info-container {
        margin-top: 20px;
        .user-name-title-group {
            display: flex;
            flex-direction: row;
            margin-left: 6px;

            .user-name-title {
                display: flex;
                flex-direction: column;      
                .user-name {
                    font-size: 16px;
                    margin-left: 20px;
                    line-height: 24px;
                }
                .user-title {
                    margin-left: 20px;
                    line-height: 16px;
                }
            }
        }
        .user-email {
          > img {
            margin-left: 16px;
          }  
          margin-top: 10px;
          .user-email-info {
              margin-left: 22px;
          }
        }
        .user-control-btn {
            display: flex;
            margin-top: 10px;
            margin-bottom: 20px;
            justify-content: flex-end;
           .ant-btn {
                height: 40px;
                font-size: 14px;
                font-weight: 500;
            }
            .user-btn-logout {
                margin-right: 16px;
                border-radius: 4px;
            }
            .user-btn-detail {
                border-radius: 4px;
                background-color: @primaryColor;
                border: @primaryColor;
                color: @whiteColor;
            }
        }
        .ant-menu-item-active.ant-menu-item-selected {
            background: none;
        }
        .ant-menu-item-selected {
            color: @seventhColor; 
            background: none;
        }
    }
    .ant-menu-item:hover{
        color: @seventhColor;
    }

    .ant-menu-item:active{
        background: transparent;
    }
    .ant-menu-item .anticon{
        font-size: 18px;
    }
}

