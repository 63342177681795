@import '@/styles/variables.less';

.language-filter-container {
    .language-filter-button {
        display: flex;
        align-items: center;
        border-color: @whiteColor;
        background-color: @whiteColor;
        &.border{
            border: 1px solid @eighthColor;
            border-radius: 4px;
            height: 36px;
            &:hover {
                color: @fourthColor;
                border: 1px solid @eighthColor;
            }
        }
        .language-filter-icon {
            margin-right: 10px;
        }
        .language-filter-text {
            margin-right: 20px;
        }
        &:hover {
            color: @fourthColor;
            border-color: @whiteColor;
        }
    }
    .ant-btn:focus {
        color: @fourthColor;
        border-color: @whiteColor;
    }
}