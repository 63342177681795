@import '@/styles/variables.less';
.login-info-container{
    margin-top: 16px;  
    border-bottom: 1px solid @thirdColor;
    padding-bottom: 56px;
    .login-info-title{
        margin-top: 16px;  
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        color: @seventhColor
    }
    .login-info-sub-title{
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: @tenthColor
    }
    .login-info-form-container{
        margin-top: 24px;
        .login-info-form{
            .label{
                font-size: 12px;
                font-weight: bold;
            }  
            .sub-label{
                font-size: 10px;
                color: @fifthColor;
            }
          
        }
        .change-password-col {
            display: flex;
            align-items: flex-end;
        }
    }
    .change-password-button{
        height: 40px;
        border-radius: 4px ;
        border: 1px solid @eighthColor;
        font-size: 14px;
        color: @fourthColor;
    }

   
}
.input{
    margin-top: 6px;
    height: 40px;
    border: 1px solid @eighthColor;
    padding: 8px 12px;
    border-radius: 4px;
    width: 95%;
    color: @fifthColor;
    &:focus{
        border-right-width: 1px !important;
        outline: 0;
    }
    &::placeholder {
        color: @fifthColor;
        opacity: 0.5;
      }
}