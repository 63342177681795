@import '@/styles/variables.less';

.sidebar-container{
    height: 100%;
    border-right: 0;
    padding-top: 24px;
    background: @sixthColor;
    box-shadow: 0px 0px 0px 1px #E0E0E0;
    .ant-menu-item-group-title{
        font-size: 10px;
        padding: 16px 0 0px 24px;
        text-transform: uppercase;
        color: @eighthColor;
    }
    .ant-menu-item{
        border-left: 4px solid @whiteColor;
        font-size: 16px; 
        color: @fourthColor;
        display: flex;
        align-items: center;
        height: 42px;
        &::after{
            border: none;
            border-right: 0px !important;
        }
        &:hover {
            color: @fourthColor;
        }
        svg {
            font-size: 20px;
        }
    }
    .ant-menu-item-selected{
        background-color: transparent !important;
        border-left-color: @primaryColor;
        color: @primaryColor ;
        a{
            color: @primaryColor ;
        }
        &:hover {
            color: @primaryColor;
        }
    }
    
}

