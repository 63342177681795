@import "./variables.less";
@import "./fonts.less";

.app-container{
    height: 100vh;
}
.ant-layout.site-layout {
  background: @whiteColor !important;
}

.ant-input-password-icon {
  svg {
    width: 24px;
    height: 24px;
  }
}
