@import '@/styles/variables.less';
.auth-container {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  .change-password-container {
    width: 60%;
    padding: 80px;
    .change-password{
      &-header {
      display: flex;
      justify-content: space-between;
      }
      &-content{
        h1{
          font-size: 24px;
          font-weight: bold;
          line-height: 32px;
          margin-top: 30px;
        }
        p {
          font-weight: normal;
          font-size: 16px;
          color: @fourthColor;
          margin-top: 30px;
          margin-bottom: 25px;
        }
        &__btn-reset {
          position: relative;
          z-index: 99;
          &-action {
            padding: 0;
            top: 5px;
            right: 0px;
            position: absolute;
            font-size: 12px;
            color: @planedTextColor;
            &:hover {
              background: rgba(0, 0, 0, 0);
            }
          }
        }
        &__btn {
          &-change {
            margin-top: 20px;
            margin-right: 20px;
          }
        }
        &__validate-error {
          margin-top: 10px;
          color: @errorTextColor;
          ul {
            padding-inline-start: 15px;
          }
        }
      }
      

    }
  }
  .illustration-container {
    width: 40%;
    background-color: #F7FBFF;
  }
  .ant-form-item-control-input-content {
    .ant-btn-primary {
      border-radius: 4px;
      min-width: 140px;
      height: 40px;
      background: @loginBtnColor;
      border-color: @loginBtnColor;
      &:hover,
      &:focus {
        background: @loginBtnColor;
        border-color: @loginBtnColor;
      }
    }
    .ant-input-password {
      border-radius: 4px;
    }
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-form-vertical .ant-form-item-label {
    padding: 0;
    > label {
      font-size: 13px;
      color: @fourthColor;
      ::after {
        content: " ";
      }
    }
  }
}
