@import '@/styles/variables.less';
.language-info-container{
    margin-top: 16px; 
    padding-bottom: 24px;
    .language-info-title{
        margin-top: 16px;  
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        color: @seventhColor
    }
    .language-info-sub-title{
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: @tenthColor
    }
    .language-info-form-container{
        margin-top: 24px;
        .language-info-form{
            .ant-btn.ant-dropdown-trigger.change-lang-drowdown{
                width: 60% !important;
                height: 40px;
                display: flex;
                align-items: center;
                border-radius: 4px;
                justify-content: space-between;
                border: 1px solid @eighthColor;
            } 
        }
       
    }
}