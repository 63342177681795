@import '@/styles/variables.less';

.setting-container{
    width: 100%;
    height: 80vh;
    background: @whiteColor;
    padding: 43px 40px 70px 19px;
    border-radius: 4px;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
    overflow-y: scroll;
    .title{
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: @seventhColor;
    }
    .sub-title{
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: @fourthColor;
        margin-top: 16px;  
    }
    .edit-info-container{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .edit-info-button{
            background: @primaryColor;
            height: 40px;
            border-radius: 4px;
            min-width: 139px;
            width: auto;
            font-size: 14px
        }
    }
   
}
