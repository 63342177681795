@import '@/styles/variables.less';

.main-calendar{
    .calendar-header-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        .calendar-header-service{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;
            .title {
                font-weight: bold;
                font-size: 24px;
                color: @seventhColor ;
            }
            .more-option-btn {
                margin-left: 16px ;
                border: none;
                transform: rotate(90deg);
                span {
                    font-size: 22px;
                }
            }
            .add-services-btn{
                &.ant-btn-primary{
                    // width: 108px;
                    height: 40px;
                    font-size: 14px;
                    background: @primaryColor;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }    
            }
            .ant-btn-square{
                width: 40px;
                height: 40px;
                border-radius: 4px;
            }
        }
        .calendar-header-option{
            display: flex;
            width: 100%;
            justify-content: space-between;
            .left {
                display: flex;
                align-items: center;
                .title{
                    font-size: 16px;
                    color: @fourthColor;
                    width: 200px;
                }
                .navigate-button {
                    .ant-btn{ 
                        border: none;
                        box-shadow: none;
                        &:focus {
                            color: @fourthColor;
                        }
                    }
                   .today-button{
                        margin-left: 16px; 
                        background: @whiteColor;
                        border: 1px solid rgba(159, 175, 189 , 0.7);
                        box-sizing: border-box;
                        border-radius: 4px;
                        width: 100px;
                        font-size: 16px;
                        &:hover {
                            color: @primaryColor;
                            border-color: @primaryColor;
                        }
                   }
                }
                
            }
            .right {
                display: flex;
                align-items: center;   
                .date-view-btn{
                    display: block;
                }
                .btn-view-calendar {
                    width: 36px;
                    height: 36px;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: none;
                    margin-right: 16px;
                    box-shadow: none;
                    // background: rgba(159, 175, 189 , 0.5);
                    // border: 1px solid  rgba(159, 175, 189 , 0.5);
                    box-sizing: border-box;
                    &.icon-xl{
                        margin-right: 32px;
                        span{
                            font-size: 24px;
                        }
                    }
                    &.icon-md{
                        margin-right: 4px;
                        span{
                            font-size: 16px;
                        }
                        
                    }
                    &.text{
                        width: auto;
                    }
                    
                    &.active {
                        // width: 36px;
                        background:@secondColor;
                        color: @primaryColor;
                    }
                }
                .ant-btn-text:hover {
                    color: @primaryColor;
                    background: transparent;
                }
                .ant-btn-text:focus {
                    background: @secondColor;
                }
            }
        }
    }
    
    
    .ant-popover-inner-content{
        box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
        border-radius: 4px;
        .ant-picker-cell-inner{
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
           
        }
        .ant-picker-cell-selected .ant-picker-cell-inner {
            &::before{
                border: none;
            }
            background: rgba(2,116,232,0.5);
            color: @primaryColor;
            border-radius: 50%;
            
        }
    }
}
